import $, {data} from 'jquery';
import {ENDERECO_API} from '../../config/constant';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {da} from 'date-fns/locale';

const RestResgateXmlPost = (config) => {
  const Arr_ = JSON.parse(config.data);

  const caminho = Arr_.dest.trim();

  if (caminho === 'incluirChavesResgate') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost = Arr_.data;
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const uid = users[3];
        console.log(dataPost);

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('incluirChavesResgate'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            console.log(xhr);

            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'deletarChave') {
    console.log('chegou');

    return new Promise(function (resolve, reject) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const uid = users[3];

        const dataPost = Arr_.data;
        console.log(dataPost);

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('deletarChave'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            console.log(xhr);

            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'salvarAdicionais') {
    return new Promise(function (resolve, reject) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const uid = users[3];
        const dataPost = Arr_.data;
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(Arr_.data))];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('salvarAdicionais'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            console.log(xhr);

            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'updateResgateEspionar') {
    return new Promise(function (resolve, reject) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const uid = users[3];
        const dataPost = Arr_.data;
        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('updateResgateEspionar'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            console.log(xhr);

            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'removerAdicionais') {
    return new Promise(function (resolve, reject) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const uid = users[3];
        const dataPost = Arr_.data;

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('removerAdicionais'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            console.log(xhr);

            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'alterarLimiteDownload') {
    return new Promise(function (resolve, reject) {
      try {
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const uid = users[3];
        const dataPost = Arr_.data;
        console.log(dataPost);

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('alterarLimiteDownload'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            console.log(xhr);

            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'insereChaveHistorico') {
    return new Promise(function (resolve, reject) {   

       try {
         const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
         const users = JSON.parse(usersRt);
         const emp = users[0];
         const uid = users[3];
         const dataPost = Arr_.dataPost;
         console.log(config);

         const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

         $.ajax({
           url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
           type: 'POST',
           dataType: 'json',
           data: {
             pp: base64_encode('resgateXML'),
             ll: base64_encode('insereChaveHistorico'),
             dd: base64_encode(JSON.stringify(dataPost_)),
           },
           success: async function (data, status, xhr) {
             resolve([200, base64_decode(data)]);
           },
           error: function (xhr, status, error) {
             console.log(xhr);

             reject([400, {message: 'erro'}]);
           },
         });
       } catch (err) {
         reject([500, {message: err}]);
       }
    });
  } else if (caminho === 'cadEmpresasGestao') {
    return new Promise(function (resolve, reject) {
      try {
        const dataPost = Arr_.dataPost;
        const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
        const users = JSON.parse(usersRt);
        const emp = users[0];
        const uid = users[3];

        const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'POST',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('cadEmpresasGestao'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            console.log(xhr);

            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  }
};
const RestResgateXmlGet = (config) => {
  const caminho = config.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const storage = JSON.parse(usersRt);
  const emp = storage[0];
  const uid = storage[3];
  // const cnpjResponsavel = config.dataPost[0];
  // const dataInicio = config.dataPost[1];
  // const dataFim = config.dataPost[2];

  if (caminho === 'getDataTableResgateXml') {
    return new Promise(function (resolve, reject) {
      const dataPost = config.dataPost;

      const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getDataTableResgateXml'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getTotalResgatados') {
    const cnpj = config.cnpj;

    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getTotalResgatados'),
            dd: base64_encode(JSON.stringify({emp, uid, cnpj})),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'statusResgateEspionar') {
    return new Promise(function (resolve, reject) {
      const cnpj = config.cnpj;
      const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(cnpj)];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('statusResgateEspionar'),
            dd: base64_encode(JSON.stringify(dataPost)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'filterDataTableByDate') {
    return new Promise(function (resolve, reject) {
      const dataPost = config.dataPost;

      const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('filterDataTableByDate'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getStatusResgateEspionar') {
    return new Promise(function (resolve, reject) {
      const dataPost = config.dataPost;
      const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getStatusResgateEspionar'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        console.log(err);
        alert(err);
        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getAdicionaisResgate') {
    return new Promise(function (resolve, reject) {
      const dataPost = config.dataPost;
      const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getAdicionaisResgate'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        console.log(err);

        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getHistoricoConsumo') {
    return new Promise(function (resolve, reject) {
      const dataPost_ = [base64_encode(emp), base64_encode(uid)];
      console.log(dataPost_);

      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getHistoricoConsumo'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            console.log(data);

            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        console.log(err);

        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getLimiteDownload') {
    return new Promise(function (resolve, reject) {
      const dataPost_ = [base64_encode(emp), base64_encode(uid)];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getLimiteDownload'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        console.log(err);

        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'verificarTesteResgate') {
    return new Promise(function (resolve, reject) {
      const dataPost_ = [base64_encode(emp), base64_encode(uid)];
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('verificarTesteResgate'),
            dd: base64_encode(JSON.stringify(dataPost_)),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        console.log(err);

        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getUFs') {
    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getUFs'),
            dd: base64_encode(JSON.stringify([base64_encode(emp), base64_encode(uid)])),
          },
          success: async function (data) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        console.log(err);

        reject([500, {message: err}]);
      }
    });
  } else if (caminho === 'getEmpresas') {
    return new Promise(function (resolve, reject) {
      try {
        $.ajax({
          url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
          type: 'Get',
          dataType: 'json',
          data: {
            pp: base64_encode('resgateXML'),
            ll: base64_encode('getEmpresas'),
            dd: base64_encode(JSON.stringify([base64_encode(emp), base64_encode(uid)])),
          },
          success: async function (data, status, xhr) {
            resolve([200, base64_decode(data)]);
          },
          error: function (xhr, status, error) {
            reject([400, {message: 'erro'}]);
          },
        });
      } catch (err) {
        console.log(err);

        reject([500, {message: err}]);
      }
    });
  }


};

export {RestResgateXmlPost, RestResgateXmlGet};
