import Swal from 'sweetalert2';
import axios from '../../../services/axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {ENDERECO_API} from '../../../config/constant';
import { statusMap } from '../Arrays';
import jsPDF from 'jspdf';
import toast from 'react-hot-toast';

/* Formata CNPJ/CPF com traços e pontos */
export const FormatCnpjCpf = (value) => {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};

export /**
 * Verifica a permissao do usuario
 *
 * @param {*} dataRestricoes
 * @param {*} restricao
 * @return {*}
 */
const VerificaPermissaoUsuario = (dataRestricoes, restricao) => {
  if (Array.isArray(dataRestricoes) && dataRestricoes.length > 0) {
    let boolRestricao = false;
    dataRestricoes.forEach((item) => {
      if (item.item === restricao) {
        boolRestricao = true;
      }
    });

    if (boolRestricao === true) {
      Swal.fire({
        title: 'Acesso Negado',
        text: 'Você não possui permissão para acessar essa funcionalidade.',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#4099ff',
      });
    }
    return boolRestricao;
  } else {
    return false;
  }
};

export const SalvaDataPeriodoPadrao = (salvarData, data) => {
  const sessiosS = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));
  const localStorage_ = localStorage.getItem(`ddespU${base64_encode(sessiosS[3])}`);
  const localDescrip = JSON.parse(base64_decode(localStorage_));
  const usr = localDescrip[1];
  if (salvarData) {
    const periodo_ = data;
    localStorage.setItem(`periodoDoc${usr}`, JSON.stringify(periodo_));
  } else {
    localStorage.removeItem(`periodoDoc${usr}`);
  }
};

export const TrataDataIniEmpresas = (dados) => {
  let empSelecionadaCOokie = localStorage.getItem('empDoc');
  let empselecionada = dados[0];

  if (empSelecionadaCOokie !== undefined && empSelecionadaCOokie !== null) {
    empSelecionadaCOokie = JSON.parse(base64_decode(empSelecionadaCOokie));

    const filtered = dados.filter((obj) => {
      return obj.value === empSelecionadaCOokie.value;
    });
    if (filtered.length > 0) {
      empselecionada = filtered[0];
    }
  }
  return empselecionada;
};

export const CheckDataUser = async () => {
  return new Promise(function (resolve, reject) {
    axios.get('/api/account/', {dest: 'checkData'}).then((res) => {
      const usersRt = sessionStorage.getItem(`ddesp`) !== null ? JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`))) : null;
      const response = res.data;
      // const possuiEmpresas = response.possuiEmpresas; //ficticio = dados Fic. | real = dados reais | false = sem dados
      // const possuiCert = response.possuiCert; //cert = possui certificado | no_cert  = não possui certificado
      // const possuiNotas = response.possuiNotas; //true = possui notas | false = não possui notas
      // const primeiroAcesso = response.primeiroAcesso; //true = primeiro acesso | false = não é primeiro acesso

      usersRt[11][0] = res.data;
      sessionStorage.setItem('ddesp', base64_encode(JSON.stringify(usersRt)));

      resolve(res.data);
    });
  });
};

//Função para baixar arquivo zip do instalador Cloud Monitor
export const DownloadCloudMonitor = () => {
  //Acessar pasta cloud_monitor e baixar o arquivo zip
  window.open(`${ENDERECO_API}download/CloudMonitor_Instalador.zip`, '_blank');
};

//Função que recarrega o sessionStorage
export const ReloadSessionStorage = async () => {
  const sessionStorage_ = JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`)));

  const usuarioId = sessionStorage_[3];
  const usuarioNome = sessionStorage_[4];
  const usuarioEmail = sessionStorage_[6];
  const varias = sessionStorage_[18];
  const empresaId = sessionStorage_[0];

  const data = [usuarioId, usuarioNome, usuarioEmail, varias, empresaId];

  try {
    const res = await axios.post('/api/initData/', {dest: 'recarregaSession', data: data});

    const response = res.data;
  } catch (error) {
    console.error('Erro ao recarregar sessionStorage:', error);
  }
};

//Função para remover acentos e caracteres especiais

export const RemoverAcentosECaracteresEspeciais = (valor) => {
  return valor
    .normalize('NFD') // Normaliza o valor em forma de decomposição (NFD)
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/[^a-zA-Z0-9\s]/g, ''); // Permite letras, números e espaços
};

// Função que gera o relatório de histórico de documentos em formato XLSX
const XLSX = require('xlsx');

const gerarXlsxTabela = (dadosTabela, colunas, visibilidade, documento, origem) => {
  let montarObjetosColunas = [];
  colunas.forEach((ordem) => {
    if (visibilidade[ordem] === true) {
      if (ordem !== 'marcador' && ordem !== 'Opções') {
        if (ordem === 'observacao') {
          montarObjetosColunas.push({anotacoes: ''});
        } else {
          montarObjetosColunas.push({[ordem]: ''});
        }
      }
    }
  });

  //

  const xlsxDados = dadosTabela.map((item) => {
    let novaColuna = {};
    montarObjetosColunas.forEach((coluna) => {
      //
      let chave = Object.keys(coluna)[0];
      if (chave === undefined) {
        chave = '';
      }
      if (documento === 'nfe') {
        novaColuna[chave] = item[chave] !== undefined ? item[chave] : '';
        if (chave === 'anotacoes') {
          novaColuna[chave] = item['observacao'] !== undefined ? item['observacao'] : '';
        }
      }
      if (documento === 'cte') {
        novaColuna[chave] = item[chave] !== undefined ? item[chave] : '';
      }
      if (documento === 'nfse') {
        if (origem === '1') {
          // NFS-e Nacional
          switch (chave) {
            case 'prestador':
              item.prestador_nome && item.prestador_cnpj_cpf ? (novaColuna[chave] = `${item.prestador_nome} - ${item.prestador_cnpj_cpf}`) : (novaColuna[chave] = 'N/A');
              break;
            case 'tomador':
              item.tomador_nome && item.tomador_cnpj_cpf ? (novaColuna[chave] = `${item.tomador_nome} - ${item.tomador_cnpj_cpf}`) : (novaColuna[chave] = 'N/A');
              break;
            case 'intermediario':
              item.interm_nome && item.interm_cnpj_cpf ? (novaColuna[chave] = `${item.interm_nome} - ${item.interm_cnpj_cpf}`) : (novaColuna[chave] = 'N/A');
              break;
            case 'iss_retido':
              novaColuna[chave] = item[chave] === 'f' ? 'Não' : 'Sim';
              break;
            case 'status':
              novaColuna[chave] = item[chave] === 'f' ? 'Autorizada' : 'Cancelada';
              break;
            case 'informacoes':
              novaColuna[chave] = item[chave] !== null ? item[chave] : 'N/A';
              break;
            case 'tipo':
              if (item[chave] === '0') {
                novaColuna[chave] = 'Prestador';
              } else if (item[chave] === '1') {
                novaColuna[chave] = 'Tomador';
              } else {
                novaColuna[chave] = 'Intermediário';
              }
              break;
            case 'cstat':
              novaColuna[chave] = statusMap[item[chave]] ? statusMap[item[chave]].situacao : statusMap['default'].situacao;
              break;
            default:
              novaColuna[chave] = item[chave] !== undefined ? item[chave] : '';
              break;
          }
        } else {
          switch (chave) {
            case 'prestador':
              item.prestador_nome && item.prestador_cnpj ? (novaColuna[chave] = `${item.prestador_nome} - ${item.prestador_cnpj}`) : (novaColuna[chave] = 'N/A');
              break;
            case 'tomador':
              item.tomador_nome && item.tomador_cnpj ? (novaColuna[chave] = `${item.tomador_nome} - ${item.tomador_cnpj}`) : (novaColuna[chave] = 'N/A');
              break;
            default:
              novaColuna[chave] = item[chave] !== undefined ? item[chave] : '';
              break;
          }
        }
      }
    });
    return novaColuna;
  });
  return xlsxDados;
};

export const DownloadXlsxTabela = (dadosTabela, colunas, visibilidade, documento, origem) => {
  const xlsDados = gerarXlsxTabela(dadosTabela, colunas, visibilidade, documento, origem);
  if (xlsDados.length === 0) {
    Swal.fire({
      title: 'Atenção',
      text: 'Não há dados para exportar.',
      icon: 'warning',
      confirmButtonText: 'OK',
      confirmButtonColor: '#4099ff',
    });
    return;
  }
  const ws = XLSX.utils.json_to_sheet(xlsDados);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `historico_${documento}`);
  const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
  const blob = new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const hoje = new Date();
  const dia = hoje.getDate().toString().padStart(2, '0');
  const mes = (hoje.getMonth() + 1).toString().padStart(2, '0');
  const ano = hoje.getFullYear();
  const nomeArquivo = `historico_${documento}_${dia}${mes}${ano}.xlsx`;

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, nomeArquivo);
  } else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = nomeArquivo;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }
};

const gerarPdfTabela = (dadosTabela, colunas, visibilidade, documento, origem) => {
  let montarObjetosColunas = [];
  colunas.forEach((ordem) => {
    if (visibilidade[ordem] === true) {
      if (ordem !== 'marcador' && ordem !== 'Opções') {
        if (ordem === 'observacao') {
          montarObjetosColunas.push({anotacoes: ''});
        } else {
          montarObjetosColunas.push({[ordem]: ''});
        }
      }
    }
  });

  const pdfDados = dadosTabela.map((item) => {
    let novaColuna = {};
    montarObjetosColunas.forEach((coluna) => {
      let chave = Object.keys(coluna)[0];
      if (chave === undefined) {
        chave = '';
      }
      novaColuna[chave] = item[chave] !== undefined ? item[chave] : '';
      return novaColuna;
    });
    return novaColuna;
  });
  return {pdfDados, montarObjetosColunas};
};

export const DownloadPdfTabela = (dadosTabela, colunas, visibilidade, documento, origem) => {
  const {pdfDados, montarObjetosColunas} = gerarPdfTabela(dadosTabela, colunas, visibilidade, documento, origem);
  if (pdfDados.length === 0) {
    Swal.fire({
      title: 'Atenção',
      text: 'Não há dados para exportar.',
      icon: 'warning',
      confirmButtonText: 'OK',
      confirmButtonColor: '#4099ff',
    });
    return;
  }
  const doc = new jsPDF();
  const hoje = new Date();
  const dia = hoje.getDate().toString().padStart(2, '0');
  const mes = (hoje.getMonth() + 1).toString().padStart(2, '0');
  const ano = hoje.getFullYear();
  const nomeArquivo = `historico_${documento}_${dia}${mes}${ano}.pdf`;

  const columns = montarObjetosColunas.map((col) => Object.keys(col)[0]);

  if (columns.length > 5) {
    Swal.fire({
      title: 'Ops',
      icon: 'info',
      showCancelButton: false,
      html: 'A quantidade de colunas excede o limite para exportação em PDF.',
      confirmButtonText: 'OK',
      confirmButtonColor: '#007bff',
      //reverseButtons: true
    });
    return;
  }

  const columnStyles = columns.reduce((acc, col, index) => {
    acc[index] = {cellWidth: 30};
    if (col === 'chave_acesso' || col === 'chave_nfse') {
      acc[index] = {cellWidth: 80};
    }
    return acc;
  }, {});

  doc.autoTable({
    head: [columns],
    body: pdfDados.map((row) => columns.map((col) => row[col])),
    styles: {theme: 'striped', fontSize: 8},
    columnStyles: columnStyles,
  });

  doc.save(nomeArquivo);
};

export const CopiarLinhasTabela = (dadosTabela, useRefTable, documento) => {
  const selecionadas = useRefTable.current?.getSelectedRowModel();
  const temSelecionadas = selecionadas?.rows?.length > 0;
  let chave = 'chave_acesso';
  switch (documento) {
    case 'nfe':
    case 'cte':
    case 'nfce':
    case 'sat':
      chave = 'chave_acesso';
      break;
    case 'nfse':
      chave = 'chave_nfse';
      break;
    default:
      chave = 'chave_acesso';
      break;
  }
  let chavesCopiadas = [];
  if (!temSelecionadas) {
    chavesCopiadas = dadosTabela.map((item) => item[chave]);
  } else {
    chavesCopiadas = selecionadas.rows.map((item) => item.original[chave]);
  }
  const textoCopiado = chavesCopiadas.join('\n');
  const textarea = document.createElement('textarea');
  textarea.value = textoCopiado;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  navigator.clipboard.writeText(textoCopiado);
  toast.success('Chaves de acesso copiadas para a área de transferência!');
};
