import React, {useContext, useState, useEffect, useRef} from 'react';
import {ListGroup, Dropdown, Card, Row, Col, Collapse, Button} from 'react-bootstrap';
import {Tooltip} from '@mui/material';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {ConfigContext} from '../../../../contexts/ConfigContext';
import ChatList from './ChatList';
import useAuth from '../../../../hooks/useAuth';
import {decode as base64_decode} from 'base-64';
import axios from '../../../../services/axios';
import lupa from '../../../../assets/images/lupa.png';
import lupav from '../../../../assets/images/lupav.png';

import {IMGPERFIL, NOMEPERFIL, TIPOTESTE, MENU_USUARIO, CHANGEDDATAUSER} from '../../../../store/actions';
import {FormatCnpjCpf} from '../../../../components/Utilitarios/Funcoes';
import {perfilAvatar0, perfilAvatar1, perfilAvatar2, perfilAvatar3, perfilAvatar4, perfilAvatar5, perfilAvatar6, perfilAvatar7, perfilAvatar8, perfilAvatar9, perfilAvatar10, perfilAvatar11, perfilAvatar12, perfilAvatar13, perfilAvatar14, perfilAvatar15, perfilAvatar16, perfilAvatar17, perfilAvatar18, perfilAvatar19, perfilAvatar20, perfilAvatar21, perfilAvatar22, perfilAvatar23, perfilAvatar24, perfilAvatar25, perfilAvatar26, perfilAvatar27, perfilAvatar28, perfilAvatar29, perfilAvatar30, perfilAvatar31, perfilAvatar32} from '../../../../components/Utilitarios/imagens/perfil';

import ModalIntegracoes from '../../../../views/modal/modalIntegracoes';
import ModalFeedback from '../../../../views/modal/feedback/modalFeedback';
import Swal from 'sweetalert2';
import {CheckDataUser} from '../../../../components/Utilitarios/Funcoes';
import {Badge, List} from 'rsuite';
import packageJson from '../../../../../package.json';
import {format} from 'date-fns';

const NavRight = (props) => {
  const configContext = useContext(ConfigContext);
  const {dispatch} = configContext;
  const ref = useRef();
  const {logout} = useAuth();
  const {rtlLayout, ws} = configContext.state;
  const [showModalIntegracoes, setShowModalIntegracoes] = useState(false);
  const [avatarAtual, setAvatarAtual] = useState(perfilAvatar1);
  const [username, setUsername] = useState(''); // faltou criar o STATE para atualizar o nome do usuário
  const [listOpen, setListOpen] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);
  const [notificacoesArray, setNotificacoesArray] = useState([]);
  const [spanNotificacoes, setSpanNotificacoes] = useState(''); // notificações
  const [isNewNoti, setIsNewNoti] = useState(false);
  const [boolAlertas, setBoolAlertas] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const storage_ = sessionStorage.getItem(`ddesp`);
  const [isDemonstracao, setIsDemonstracao] = useState(false);
  const [accordionKey, setAccordionKey] = useState(0);
  const [dataIni, setDataIni] = useState([]);
  const [ocultaMenus, setOcultaMenus] = useState(false);

  const [displayTrocarEmpresa, setDisplayTrocarEmpresa] = useState(false);
  const [displayFecharDropConta, setDisplayFecharDropConta] = useState(false);
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  let TipoEmpresa = '';
  let nomeEmpresa = '';
  let cnpjCpf = '';
  let tituloPlano = <></>;
  let usersRt = null;
  let users = null;
  let varias = false;
  let bloqConta = false;
  let primeiroAcesso = false;
  let possuiEmpresas = false;

  if (storage_ !== null) {
    usersRt = sessionStorage.getItem(`ddesp`) !== null ? JSON.parse(base64_decode(sessionStorage.getItem(`ddesp`))) : null;
    users = usersRt;
    TipoEmpresa = users[11][0]['possuiEmpresas'];
    nomeEmpresa = users[1].toUpperCase();
    cnpjCpf = users[2] !== null && users[2] > '' ? FormatCnpjCpf(users[2]) : '';
    varias = users[18];
    bloqConta = users[28];
    primeiroAcesso = users[11][0].primeiroAcesso;
    possuiEmpresas = users[11][0].possuiEmpresas;

    tituloPlano =
      users[16] === true ? (
        ''
      ) : (
        <Tooltip
          title='Plano Contratado'
          placement='left'
          arrow
        >
          <span className='f-w-600 f-14'>{users[17]}</span>
        </Tooltip>
      );
  }

  const handleAjuda = () => {
    //
    const MySwal = Swal;
    MySwal.fire({
      title: 'Só um instante',
      text: 'Estamos carregando os tutoriais.',
      icon: 'info',
      confirmButtonText: 'OK',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      timer: 2000,
      didOpen: () => {
        MySwal.showLoading();
      },
    });
    axios.get('/api/users/', {dest: 'ajuda', dataPost: [location.pathname]}).then((res) => {
      MySwal.hideLoading();
      const data = res.data;

      let buttonLink = '';
      let buttonChat = '<a class="btn btn-success rounded btn-sm ml-2" target="_blank" href="https://tawk.to/chat/62dee31b54f06e12d88b4709/1g8rae9bv">Chat</a>';

      if (res.data.length > 0) {
        buttonLink = `<a class="btn btn-primary rounded btn-sm"  target="_blank" href=${data[0].link_tutorial}>Tutoriais</a>`;
        window.open(data[0].link_tutorial, '_blank');
      }
    });
  };

  const handleFeedback = () => {
    setIsOpenFeedback(true);
  };

  const handleNotificacoes = () => {
    axios.get('/api/users/', {dest: 'notificacoes'}).then((res) => {
      let countNovas = 0;

      const notificacoes_ = res.data.map((notificacao) => {
        // menuitem
        if (notificacao.data_hora_leitura === 'null' || notificacao.data_hora_leitura === null) {
          // menuitem

          countNovas++;
        }
        return (
          <ListGroup.Item
            as='li'
            bsPrefix=' '
            className='notification'
            key={notificacao.key}
          >
            <Card className={`m-1 pointer ${notificacao.fundo !== '' ? 'bg-' + notificacao.fundo : ''}`}>
              <Card.Header className='p-2'>
                <Card.Title className='m-0 h6'>
                  <span className='float-right'>
                    <img
                      src={notificacao.data_hora_leitura !== 'null' && notificacao.data_hora_leitura !== null ? lupav : lupa}
                      width={15}
                    />
                  </span>
                  {notificacao.titulo}
                </Card.Title>
              </Card.Header>
              <Card.Body className='p-2'>
                {/* <h6>{notificacao.titulo}</h6> */}
                <p>{notificacao.texto}</p>
              </Card.Body>
              <Card.Footer className='p-1'>
                <span className='float-left f-10'>{notificacao.data_hora_inicio.toLocaleString('pt-BR', {timeZone: 'UTC'})}</span>
                {notificacao.link !== null && notificacao.link !== 'null' && (
                  <a
                    target='_blank'
                    href={notificacao.link}
                    className='rounded badge-light-success float-right p-1'
                    style={{fontSize: '12px'}}
                  >
                    Visualizar
                  </a>
                )}
              </Card.Footer>
            </Card>
          </ListGroup.Item>
        );
      });

      setNotificacoesArray(notificacoes_);
      setSpanNotificacoes(countNovas > 0 ? countNovas : '');
    });
  };

  useEffect(() => {
    setOcultaMenus(false);
    let imgPerfil_ = users[12];
    let bloqConta_ = users[28];
    const vincGoogle = users[25];
    const imgGoogle = users[24];
    const expirou = users[29];   

    if (bloqConta_ === 't') {
      setOcultaMenus(true);
      if (location.pathname.indexOf('liberarAcesso') === -1) {
        history.push('/liberarAcesso');
        return;
      }
    } else {
      if (primeiroAcesso === true) {
        
        setOcultaMenus(true);
        if (location.pathname.indexOf('boasVindas') === -1 && location.pathname.indexOf('planos') === -1) {
          history.push('/inicio/boasVindas');
          return;
        }
      } else if (possuiEmpresas === false) {
        // PossuiEmpresas
        setOcultaMenus(true);
        if (location.pathname.indexOf('tipoTeste') === -1 || location.pathname.indexOf('primeiraEmpresa') === -1) {
          history.push('/inicio/tipoTeste');
          return;
        }
      }
    }

    if (vincGoogle === 't' && imgGoogle !== null && imgGoogle !== '') {
      imgPerfil_ = imgGoogle;
      configContext.dispatch({type: IMGPERFIL, imgPerfil: imgPerfil_});
    } else {
      if (imgPerfil_ > 0) {
        const imgPerfil = parseInt(imgPerfil_);
        configContext.dispatch({type: IMGPERFIL, imgPerfil: imgPerfil});
      } else {
        configContext.dispatch({type: IMGPERFIL, imgPerfil: 0});
      }
    }

    if (usersRt !== null) {
      const username_ = usersRt[4].split(' ');
      setUsername(username_[0]);
      configContext.dispatch({type: NOMEPERFIL, nomePerfil: username_[0]});
    }
    if (usersRt[17].indexOf('DEMO') !== -1 || usersRt[17].indexOf('demo') !== -1) {
      setIsDemonstracao(true);
    }

    if (localStorage.getItem('notyEsp__') !== null) {
      const notificacoes__ = JSON.parse(localStorage.getItem('notyEsp__'));
      setNotificacoesArray(notificacoes__);
      setIsNewNoti(true);
    }

    const fetchDadosIni = async () => {
      const response = await CheckDataUser();
      setDataIni(response);
      if (response.possuiEmpresas === 'ficticio') {
        dispatch({type: TIPOTESTE, tipoTeste: 1});
      } else {
        dispatch({type: TIPOTESTE, tipoTeste: 0});
      }
    };
    // handleTimerUser(configContext, location);
    fetchDadosIni();
    handleNotificacoes();
    setInterval(() => { handleNotificacoes();}, 300000);
     
  }, []);

  const handleReturnImage = (imgPerfil) => {
    switch (imgPerfil) {
      case 1:
        return perfilAvatar1;
      case 2:
        return perfilAvatar2;
      case 3:
        return perfilAvatar3;
      case 4:
        return perfilAvatar4;
      case 5:
        return perfilAvatar5;
      case 6:
        return perfilAvatar6;
      case 7:
        return perfilAvatar7;
      case 8:
        return perfilAvatar8;
      case 9:
        return perfilAvatar9;
      case 10:
        return perfilAvatar10;
      case 11:
        return perfilAvatar11;
      case 12:
        return perfilAvatar12;
      case 13:
        return perfilAvatar13;
      case 14:
        return perfilAvatar14;
      case 15:
        return perfilAvatar15;
      case 16:
        return perfilAvatar16;
      case 17:
        return perfilAvatar17;
      case 18:
        return perfilAvatar18;
      case 19:
        return perfilAvatar19;
      case 20:
        return perfilAvatar20;
      case 21:
        return perfilAvatar21;
      case 22:
        return perfilAvatar22;
      case 23:
        return perfilAvatar23;
      case 24:
        return perfilAvatar24;
      case 25:
        return perfilAvatar25;
      case 26:
        return perfilAvatar26;
      case 27:
        return perfilAvatar27;
      case 28:
        return perfilAvatar28;
      case 29:
        return perfilAvatar29;
      case 30:
        return perfilAvatar30;
      case 31:
        return perfilAvatar31;
      case 32:
        return perfilAvatar32;
      default:
        return perfilAvatar0;
    }
  };

  const handleAtivarAlertas = () => {
    setBoolAlertas(!boolAlertas);
  };

  useEffect(() => {
    if (configContext.state.changedDataUser === true) {
      axios.get('/api/users/', {dest: 'carregaMenu'}).then((res) => {
        const response = res.data;

        const menuUsuario = response;
        configContext.dispatch({type: MENU_USUARIO, menuUsuario: menuUsuario});
        configContext.dispatch({type: CHANGEDDATAUSER, changedDataUser: false});
      });
    }
  }, []);

  // useEffect(() => {
  //   if (isNewNoti === true) {
  //     const notificacoes_ = notificacoesArray;
  //     if (notificacoes_.length > 0) {
  //       let count = 0;
  //       const montaNotificacoes = notificacoes_.map((notificacao) => {
  //         const imgUs = handleReturnImage(parseInt(notificacao.img));
  //         const message = notificacao.message.split('|');
  //         count++;
  //         return (
  //           <ListGroup.Item
  //             as='li'
  //             bsPrefix=' '
  //             className='notification'
  //             key={'notificacao' + count.toString()}
  //           >
  //             <Media>
  //               <img
  //                 className='img-radius'
  //                 src={imgUs}
  //                 alt='Generic placeholder'
  //               />
  //               <Media.Body>
  //                 <p>
  //                   <strong>{notificacao.usuario}</strong>
  //                   <span className='n-time text-muted'>
  //                     <i className='icon feather icon-clock m-r-10' />
  //                     {notificacao.dataHora}
  //                   </span>
  //                 </p>
  //                 <p>{message[1]}</p>
  //               </Media.Body>
  //             </Media>
  //           </ListGroup.Item>
  //         );
  //       });
  //       setNotificacoes(montaNotificacoes);
  //       localStorage.setItem('notyEsp__', JSON.stringify(notificacoesArray));
  //     }
  //     setIsNewNoti(false);
  //   }
  // }, [isNewNoti]);

  useEffect(() => {
    const {imgPerfil, nomePerfil} = configContext.state;

    if (isNaN(imgPerfil)) {
      setUsername(nomePerfil);
      setAvatarAtual(imgPerfil);

      return;
    }
    // if (imgPerfil.indexOf('http') !== -1) {
    //   setUsername(nomePerfil);
    //   setAvatarAtual(imgPerfil);
    //
    // }

    const img_ = handleReturnImage(imgPerfil);

    setAvatarAtual(img_);
    setUsername(nomePerfil);
  }, [configContext.state.imgPerfil, configContext.state.nomePerfil]);

  const handleLogout = async () => {
    try {
      logout();
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <ListGroup
        as='ul'
        bsPrefix=' '
        className='navbar-nav ml-auto'
      >
        {isDemonstracao === true && (
          <ListGroup.Item
            as='li'
            bsPrefix=' '
          >
            <Tooltip title='Planos'>
              <Link to='/planos'>
                <i className='fa fa-shopping-cart pointer f-14' />
              </Link>
            </Tooltip>
          </ListGroup.Item>
        )}
        <ListGroup.Item
          as='li'
          bsPrefix=' '
        >
          <Dropdown
            alignRight={!rtlLayout}
            // onClick={() => setSpanNotificacoes('')}
          >
            <Dropdown.Toggle
              as={Link}
              variant='link'
              to='#'
              id='dropdown-help'
            >
              <i className='fa fa-question-circle pointer f-13 icon' />
            </Dropdown.Toggle>
            <Dropdown.Menu
              alignRight
              className='profile-notification'
            >
              <h6 className='mt-3 mb-2 mx-3 text-muted'>Ajuda</h6>
              <hr className='mt-1 mb-0' />
              <ListGroup
                as='ul'
                bsPrefix=' '
                variant='flush'
                className='pro-body mt-0'
              >
                {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-settings"/> Settings</Link></ListGroup.Item> */}

                <ListGroup.Item
                  as='li'
                  bsPrefix=' '
                >
                  <Link
                    to='#!'
                    onClick={() => handleAjuda(configContext, location)}
                    className='dropdown-item'
                  >
                    <i className='fa fa-book pointer f-13' /> Central do conhecimento
                    <div className='mt-2 text-left ml-2'>
                      <span className='f-13 text-black-50 ml-3'>Tutoriais e instruções de uso da plataforma</span>
                    </div>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item
                  as='li'
                  bsPrefix=' '
                >
                  <a
                    href='https://tawk.to/appespiaocloud'
                    className='dropdown-item text-decoration-none'
                  >
                    <i className='fa fa-comment pointer f-13' /> Chat ao vivo
                    <div className='mt-2 text-left ml-2'>
                      <span className='f-13 text-black-50 ml-3'>Converse em tempo real</span>
                      <br/>
                      <span className='f-13 text-black-50 ml-3'>Segunda a Sexta</span>
                      <br />
                      <span className='f-13 text-black-50 ml-3'>&nbsp; 8h:00 - 11h:15</span>
                      <br />
                      <span className='f-13 text-black-50 ml-3'>13h:00 - 17h:15</span>
                    </div>
                  </a>
                </ListGroup.Item>
              </ListGroup>
              <small className='float-right p-2'>
                <a href='#'>{`v.${packageJson.version}`}</a>
              </small>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
        <ListGroup.Item
          as='li'
          bsPrefix=' '
        >
          <Dropdown
            alignRight={!rtlLayout}
            onClick={(e) => {
              axios.get('/api/users/', {dest: 'notificacoesLidas'}).then((res) => {});
              setSpanNotificacoes('');
            }}
          >
            <Dropdown.Toggle
              as={Link}
              variant='link'
              to='#'
              id='dropdown-basic'
            >
              <i className='feather icon-bell icon' />
              <span className='badge badge-pill badge-danger'>{spanNotificacoes}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className='pb-0'>
              <h6 className='ml-3 text-c-blue '>
                <span className='mdi mdi-bell'></span>
              </h6>
              <ListGroup
                as='ul'
                bsPrefix=' '
                variant='flush'
                className='px-2'
                style={{maxHeight: '350px', overflow: 'auto'}}
              >
                {notificacoesArray}
              </ListGroup>
              <div className='border-0 m-0 p-0 text-center hei-45'>
                <Link
                  to='/notificacoes/'
                  className='text-center '
                  style={{fontSize: '12px', color: '#4099ff'}}
                >
                  Ver tudo
                </Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
        {/* <ListGroup.Item
          as='li'
          bsPrefix=' '
        >
          <Dropdown
            alignRight={!rtlLayout}
            onClick={() => setSpanNotificacoes('')}
          >
            <Dropdown.Toggle
              as={Link}
              variant='link'
              to='#'
              id='dropdown-basic'
            >
              <i className='feather icon-bell icon' />
              <span className='badge badge-pill badge-danger'>{spanNotificacoes}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              alignRight
              className='notification notification-scroll'
            >
              <div className='noti-head'>
                <h6 className='d-inline-block m-b-0'>Notificações!</h6>
                <div className='float-right'>
                  <Link
                    to='#'
                    onClick={() => {
                      setNotificacoesArray([]);
                      setNotificacoes([]);
                      setSpanNotificacoes('');
                      localStorage.removeItem('notyEsp__');
                    }}
                  >
                    Limpar todas
                  </Link>
                </div>
              </div>
              <PerfectScrollbar>
                <ListGroup
                  as='ul'
                  bsPrefix=' '
                  variant='flush'
                  className='noti-body'
                >
                  {notificacoes}
                </ListGroup>
              </PerfectScrollbar>
              <div className='noti-footer'>
                <Link to='#'>show all</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item> */}

        <ListGroup.Item
          as='li'
          bsPrefix=' '
        >
          <Dropdown
            alignRight={!rtlLayout}
            className='drp-user'
            id='menuRP'
          >
            <Dropdown.Toggle
              as={Link}
              variant='link'
              to='#'
              id='dropdown-basic'
            >
              <img
                src={avatarAtual}
                className='img-radius border-none wid-40'
                alt='User Profile'
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              alignRight
              ref={ref}
              className='profile-notification'
              id='menuR'
            >
              <div className='pro-head'>
                <img
                  src={avatarAtual}
                  className='img-radius'
                  alt='User Profile'
                />
                <span>{username}</span>

                <Tooltip
                  title={'Sair'}
                  placement='top'
                  arrow
                >
                  <Link
                    to='#'
                    className='dud-logout'
                    onClick={handleLogout}
                  >
                    <i className='mdi mdi-logout f-16' />
                  </Link>
                </Tooltip>
              </div>
              <Row className=' align-items-center justify-content-center bg-c-green text-white mt-0'>
                <Col sm={12}>
                  <Card className='mt-0 shadow-none bg-c-green mb-0'>
                    <Card.Header className='py-1 px-0'>
                      <Card.Title
                        as='h5'
                        className=' w-100'
                      >
                        <Tooltip
                          title='Conta Atual'
                          placement='top'
                          arrow
                        >
                          <Link
                            to='#'
                            onClick={() => {
                              setAccordionKey(accordionKey !== 1 ? 1 : 0);
                            }}
                            aria-controls='accordion1'
                            aria-expanded={accordionKey === 1}
                            className={'shadow-none text-decoration-none'}
                          >
                            <p className='mb-0 f-13 text-white ml-2 mt-0 f-w-400'>
                              <span className='mdi mdi-office-building f-13 text-white mr-2'></span>
                              {nomeEmpresa}
                              <span className={`float-right mdi ${accordionKey === 1 ? 'mdi-arrow-up' : 'mdi-arrow-down'} mr-1`}></span>
                            </p>
                          </Link>
                        </Tooltip>
                      </Card.Title>
                    </Card.Header>
                    <Collapse
                      in={accordionKey === 1}
                      className='bg-primary'
                    >
                      <div id='accordion1'>
                        <Card.Body className='pb-1 px-2'>
                          <Row className=' align-items-center justify-content-left text-white'>
                            <Col>
                              <p className='mt-2 mb-0 f-12 text-white ml-2 '>
                                {isDemonstracao === false ? cnpjCpf : ''}
                                <br />
                                {nomeEmpresa}
                                {tituloPlano != '' ? (
                                  <>
                                    <br />

                                    {tituloPlano}
                                  </>
                                ) : (
                                  ''
                                )}
                              </p>
                            </Col>
                          </Row>
                          <Row className='mb-0 mt-0'>
                            <Col>
                              <hr className='text-white bg-white mb-1 mt-1' />

                              <Link
                                to={'#!'}
                                className={'shadow-none text-decoration-none text-white f-12 float-right'}
                                onMouseEnter={() => {
                                  setDisplayFecharDropConta(true);
                                }}
                                onMouseLeave={() => {
                                  setDisplayFecharDropConta(false);
                                }}
                                onClick={() => {
                                  setAccordionKey(accordionKey !== 1 ? 1 : 0);
                                }}
                              >
                                {displayFecharDropConta === true && 'Fechar'}
                                <span className='ml-2 mdi mdi-close-circle-outline f-13'></span>
                              </Link>
                              {varias === true && (
                                <Link
                                  to={'#!'}
                                  className={'shadow-none text-decoration-none text-white f-12 float-left'}
                                  onMouseEnter={() => {
                                    setDisplayTrocarEmpresa(true);
                                  }}
                                  onMouseLeave={() => {
                                    setDisplayTrocarEmpresa(false);
                                  }}
                                  onClick={() => {
                                    sessionStorage.removeItem('ddesp');
                                    window.location.href = '/auth/acesso-varias-empresas';
                                  }}
                                >
                                  <span className='mdi mdi-arrow-u-left-top-bold'>{displayTrocarEmpresa === true && ' Trocar empresa'}</span>
                                </Link>
                              )}
                            </Col>
                          </Row>
                        </Card.Body>
                      </div>
                    </Collapse>
                  </Card>
                </Col>
              </Row>

              <hr className='mb-0 mt-0' />
              <ul className='list-unstyled'>
                {ocultaMenus === false && (
                  <>
                    <Dropdown.Item
                      as='li'
                      bsPrefix=' '
                      className='mt-2'
                    >
                      <Link
                        to='/usuarios/editarPerfil'
                        className='dropdown-item'
                      >
                        <i className='mdi mdi-account-edit f-16' /> Editar Perfil
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as='li'
                      bsPrefix=' '
                    >
                      <Link
                        to='#'
                        className='dropdown-item'
                        onClick={() => setShowModalIntegracoes(true)}
                      >
                        <i className='mdi mdi-connection f-16' /> Integrações
                        {/* <Badge
                          color='blue'
                          content={'Em breve'}
                          className='ml-2 f-10 rounded'
                        /> */}
                      </Link>
                    </Dropdown.Item>
                    {!isDemonstracao && (
                      <Dropdown.Item
                        as='li'
                        bsPrefix=' '
                      >
                        <Link
                          to='/meuPlano'
                          //to='#'
                          className='dropdown-item'
                        >
                          <i className=' mdi mdi-order-bool-ascending-variant f-16' /> Meu plano
                          {/* <Badge
                    color='blue'
                    content={'Em breve'}
                    className='ml-2 f-10 rounded'
                  /> */}
                        </Link>
                      </Dropdown.Item>
                    )}
                    {!isDemonstracao && (
                      <Dropdown.Item
                        as='li'
                        bsPrefix=' '
                      >
                        <Link
                          to='/faturas'
                          className='dropdown-item'
                        >
                          <i className=' fa fa-hand-holding-usd f-16' /> Faturas
                          {/* <Badge
                            color='blue'
                            content={'Em breve'}
                            className='ml-2  f-10 rounded'
                          /> */}
                        </Link>
                      </Dropdown.Item>
                    )}
                  </>
                )}
                <Dropdown.Item
                  as='li'
                  bsPrefix=' '
                  className='mb-2'
                >
                  <Link
                    to='#'
                    className='dropdown-item'
                    onClick={handleLogout}
                  >
                    <i className='mdi mdi-logout f-16' /> Sair
                  </Link>
                </Dropdown.Item>
              </ul>

              {/* <ListGroup
                as='ul'
                bsPrefix=' '
                variant='flush'
                className='pro-body pt-1'
              >
                 <ListGroup.Item
                  as='li'
                  bsPrefix=' '
                  onClick={() => {
                    const el = document.getElementById('menuR');
                    const el3 = document.getElementById('menuRP');
                    const el2 = document.getElementById('dropdown-basic');
                    //  el.classList.remove('show');
                    el3.classList.toggle('show');
                    //     el2.ariaExpanded = false;
                  }}
                >
                  <Link
                    to='/usuarios/editarPerfil'
                    className='dropdown-item'
                  >
                    <i className='mdi mdi-account-edit f-16' /> Editar perfil
                  </Link>
                </ListGroup.Item>               
                <ListGroup.Item
                  as='li'
                  bsPrefix=' '
                >
                  <Link
                    to='#'
                    className='dropdown-item'
                    // onClick={() => setShowModalIntegracoes(true)}
                  >
                    <i className='mdi mdi-connection f-16' /> Integrações
                    <Badge
                      color='blue'
                      content={'Em breve'}
                      className='ml-2 f-10 rounded'
                    />
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item
                  as='li'
                  bsPrefix=' '
                >
                  <Link
                    to='#'
                    className='dropdown-item'
                  >
                    <i className=' mdi mdi-order-bool-ascending-variant f-16' /> Meu plano
                    <Badge
                      color='blue'
                      content={'Em breve'}
                      className='ml-2 f-10 rounded'
                    />
                  </Link>
                </ListGroup.Item>
                {isDemonstracao === false && (
                  <ListGroup.Item
                    as='li'
                    bsPrefix=' '
                  >
                    <Link
                      to='/faturas'
                      className='dropdown-item'
                    >
                      <i className=' fa fa-hand-holding-usd f-16' /> Faturas
                      <Badge
                        color='blue'
                        content={'Em breve'}
                        className='ml-2  f-10 rounded'
                      />
                    </Link>
                  </ListGroup.Item>
                )}
                <ListGroup.Item
                  as='li'
                  bsPrefix=' '
                >
                  <Link
                    to='#'
                    className='dropdown-item'
                    onClick={handleLogout}
                  >
                    <i className='mdi mdi-logout f-16' /> Sair
                  </Link>
                </ListGroup.Item>
              </ListGroup> */}
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList
        listOpen={listOpen}
        closed={() => setListOpen(false)}
      />
      {showModalIntegracoes === true && (
        <ModalIntegracoes
          showModalIntegracoes={showModalIntegracoes}
          setShowModalIntegracoes={setShowModalIntegracoes}
        />
      )}

      <ModalFeedback
        show={isOpenFeedback}
        handleClose={() => setIsOpenFeedback(false)}
        handleFeedback={handleFeedback}
      />
    </React.Fragment>
  );
};

export default NavRight;
