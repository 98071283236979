import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

/*
 *  Array de filtros avançados para NFe
 *  @type {array}
 */
export /** @type {array} */
const ArrayFiltrosAvancadosNfe = [
  {value: 'chave_acesso', label: 'Chave de acesso', tipo: 'texto'}, //ok
  {value: 'nota', label: 'Nota', tipo: 'numerico'}, //ok
  {value: 'manifestacao', label: 'Manifestação'}, //ok
  {value: 'valor_total_nota', label: 'Total'},
  {value: 'situacao_atual', label: 'Situação'}, //ok
  {value: 'emitente_cnpj', label: 'CNPJ emitente'}, //ok
  {value: 'emitente_nome', label: 'Nome emitente'}, //ok
  {value: 'emitente_uf', label: 'UF emitente'}, //ok
  {value: 'emitente_ie', label: 'IE emitente'}, //ok
  {value: 'emitente_municipio', label: 'Emitente municipio'}, //ok
  {value: 'destinatario_cnpj_cpf', label: 'CNPJ destinatário'}, //ok
  {value: 'destinatario_nome', label: 'Nome destinatário'}, //ok
  {value: 'destinatario_uf', label: 'UF destinatário'}, //ok
  {value: 'tipo', label: 'Emitida como'}, //ok
  {value: 'nsu_arquivo', label: 'NSU Arquivo XML'}, //ok
  {value: 'nsu_resumo', label: 'NSU Resumo'}, //ok
  {value: 'data_hora_inclusao_arquivo', label: 'Data inclusão do XML'},
  {value: 'data_hora_inclusao_resumo', label: 'Data inclusão do Resumo'},
];

/*
 *  Array de filtros avançados para Produtos NFe
 *  @type {array}
 */
export /** @type {array} */
const ArrayFiltrosAvancadosProdutosNfe = [
  {value: 'codigo', label: 'Código'},
  {value: 'descricao', label: 'Descrição'},

  {value: 'ncm', label: 'NCM'},
  {value: 'cfop', label: 'CFOP'},
  {value: 'unidade', label: 'Unidade'},
  {value: 'quantidade', label: 'Quantidade'},
  {value: 'valor_unitario', label: 'Valor unitário'},
  {value: 'valor_total', label: 'Valor total'},
];

/*
 *  Array de filtros avançados para CTe
 *  @type {array}
 */
export /** @type {array} */
const ArrayFiltrosAvancadosCte = [
  {value: 'chave_acesso', label: 'Chave de acesso', tipo: 'texto'}, //ok
  {value: 'cte', label: 'Número do CT-e ', tipo: 'numerico'}, //ok
  //{ value: 'manifestacao', label: 'Manifestação' }, //ok
  //{ value: 'valor_total_nota', label: 'Total' },
  {value: 'situacao_atual', label: 'Situação'}, //ok
  {value: 'emitente_cnpj', label: 'CNPJ emitente'}, //ok
  {value: 'emitente_nome', label: 'Nome emitente'}, //ok
  {value: 'emitente_uf', label: 'UF emitente'}, //ok
  {value: 'emitente_ie', label: 'IE emitente'}, //ok
  {value: 'municipio', label: 'Emitente municipio'}, //ok
  {value: 'destinatario_cnpj', label: 'CNPJ destinatário'}, //ok
  {value: 'destinatario_nome', label: 'Nome destinatário'}, //ok
  // {value: 'destinatario_uf', label: 'UF destinatário'}, //ok
  {value: 'tomador_cnpj_cpf', label: 'CNPJ tomador'},
  {value: 'tomador_nome', label: 'Nome tomador'},
  {value: 'tomador_uf', label: 'UF tomador'},
  {value: 'expedidor_nome', label: 'Nome expedidor'},
  {value: 'expedidor_cnpj', label: 'CNPJ expedidor'},
  {value: 'remetente_nome', label: 'Nome remetente'},
  {value: 'remetente_cnpj', label: 'CNPJ remetente'},
  {value: 'recebedor_nome', label: 'Nome recebedor'},
  {value: 'recebedor_cnpj', label: 'CNPJ recebedor'},
  // { value: 'documento', label: 'Documento' }, //ok
  {value: 'nsu', label: 'NSU'}, //ok
  {value: 'data_hora_inclusao', label: 'Data inclusao'},
  {value: 'cfop', label: 'CFOP'},
  {value: 'natureza_operacao', label: 'Natureza da operação'},
  //{ value: 'codigo', label: 'Código' },
  //{ value: 'descricao', label: 'Descrição' },
  //{ value: 'tipo', label: 'Emitida como' }, //ok
  //{ value: 'ncm', label: 'NCM' },
  //{ value: 'unidade', label: 'Unidade' },
  //{ value: 'quantidade', label: 'Quantidade' },
  //{ value: 'valor_unitario', label: 'Valor unitário' },
  //{ value: 'valor_total', label: 'Valor total' },
];

/*
 * Filtros avançados para NFC-e
 * @type {array}
 */
export /** @type {array} */
const ArrayFiltrosAvancadosNfce = [
  {value: 'chave_acesso', label: 'Chave de acesso'}, //ok
  {value: 'nota', label: 'Número da NFC-e '}, //ok
  {value: 'emitente_cnpj', label: 'CNPJ emitente'}, //ok
  {value: 'emitente_nome', label: 'Nome emitente'}, //ok
  {value: 'emitente_uf', label: 'UF emitente'}, //ok
  {value: 'emitente_ie', label: 'IE emitente'}, //ok
  {value: 'destinatario_cnpj_cpf', label: 'CNPJ destinatário'}, //ok
  {value: 'destinatario_nome', label: 'Nome destinatário'}, //ok
  {value: 'destinatario_uf', label: 'UF destinatário'}, //ok
];

/*
 * Filtros avançados para SAT
 * @type {array}
 */
export /** @type {array} */
const ArrayFiltrosAvancadosSat = [
  {value: 'chave_acesso', label: 'Chave de acesso'}, //ok
  {value: 'cfe', label: 'Número do SAT/CF-e'}, //ok
  {value: 'emitente_cnpj', label: 'CNPJ emitente'}, //ok
  {value: 'emitente_nome', label: 'Nome emitente'}, //ok
  {value: 'emitente_uf', label: 'UF emitente'}, //ok
  {value: 'emitente_ie', label: 'IE emitente'}, //ok
  {value: 'destinatario_cnpj_cpf', label: 'CNPJ destinatário'}, //ok
  {value: 'destinatario_nome', label: 'Nome destinatário'}, //ok
  {value: 'destinatario_uf', label: 'UF destinatário'}, //ok
];

/*
 * Filtros avançados para NFS-e nacional
 * @type {array}
 * */
export /** @type {array} */
const ArrayFiltrosAvancadosNfse = [
  {value: 'nfse', label: 'Número da NFS-e '}, //ok
  {value: 'tipo', label: 'Tipo'},
  {value: 'prestador_cnpj', label: 'CNPJ prestador'},
  {value: 'prestador_nome', label: 'Nome prestador'},
  {value: 'prestador_inscricao', label: 'Incrição prestador'},
  {value: 'prestador_codigo_municipio', label: 'Cód. Mun. Prestador'},
  {value: 'tomador_cnpj', label: 'CNPJ Tomador'},
  {value: 'tomador_nome', label: 'Nome Tomador'},
  //{ value: 'valor_servico', label: 'Valor serviço' },
  //{ value: 'valor_iss', label: 'Valor ISS' },
  //{ value: 'valor_liquido', label: 'Valor líquido' },
  //{ value: 'iss_liquido', label: 'ISS líquido' },
  //{ value: 'iss_retido', label: 'ISS retido' },
  //{ value: 'codigo_servico', label: 'Código serviço' },
  //{ value: 'descricao', label: 'Descrição' },
  //{ value: 'prefeitura', label: 'Prefeitura' },
  //{ value: 'data_hora_inclusao', label: 'Data inclusão'}
];

// export const ArrayFiltrosAvancadosProdutosCte = [
//     { value: 'codigo', label: 'Código' },
//     { value: 'descricao', label: 'Descrição' },

//     { value: 'ncm', label: 'NCM' },
//     { value: 'cfop', label: 'CFOP' },
//     { value: 'unidade', label: 'Unidade' },
//     { value: 'quantidade', label: 'Quantidade' },
//     { value: 'valor_unitario', label: 'Valor unitário' },
//     { value: 'valor_total', label: 'Valor total' },
// ];

/*
 *  Array de filtros avançados com UFs Abreviados
 *  @type {array}
 */
export /** @type {array} */
const FiltrosUF = [
  {value: 'AC', label: 'AC'},
  {value: 'AL', label: 'AL'},
  {value: 'AP', label: 'AP'},
  {value: 'AM', label: 'AM'},
  {value: 'BA', label: 'BA'},
  {value: 'CE', label: 'CE'},
  {value: 'DF', label: 'DF'},
  {value: 'ES', label: 'ES'},
  {value: 'GO', label: 'GO'},
  {value: 'MA', label: 'MA'},
  {value: 'MT', label: 'MT'},
  {value: 'MS', label: 'MS'},
  {value: 'MG', label: 'MG'},
  {value: 'PA', label: 'PA'},
  {value: 'PB', label: 'PB'},
  {value: 'PR', label: 'PR'},
  {value: 'PE', label: 'PE'},
  {value: 'PI', label: 'PI'},
  {value: 'RJ', label: 'RJ'},
  {value: 'RN', label: 'RN'},
  {value: 'RS', label: 'RS'},
  {value: 'RO', label: 'RO'},
  {value: 'RR', label: 'RR'},
  {value: 'SC', label: 'SC'},
  {value: 'SP', label: 'SP'},
  {value: 'SE', label: 'SE'},
  {value: 'TO', label: 'TO'},
];

export /**
 * Retorna a UF abreviado atraves do codigo da UF
 *
 * @param {string} codigoUF
 * @return {string}
 */
const retornaUFporCodigo = (codigoUF) => {
  const arrUF = [
    {value: '12', label: 'AC'},
    {value: '27', label: 'AL'},
    {value: '16', label: 'AP'},
    {value: '13', label: 'AM'},
    {value: '29', label: 'BA'},
    {value: '23', label: 'CE'},
    {value: '53', label: 'DF'},
    {value: '32', label: 'ES'},
    {value: '52', label: 'GO'},
    {value: '21', label: 'MA'},
    {value: '51', label: 'MT'},
    {value: '50', label: 'MS'},
    {value: '31', label: 'MG'},
    {value: '15', label: 'PA'},
    {value: '25', label: 'PB'},
    {value: '41', label: 'PR'},
    {value: '26', label: 'PE'},
    {value: '22', label: 'PI'},
    {value: '33', label: 'RJ'},
    {value: '24', label: 'RN'},
    {value: '43', label: 'RS'},
    {value: '11', label: 'RO'},
    {value: '14', label: 'RR'},
    {value: '42', label: 'SC'},
    {value: '35', label: 'SP'},
    {value: '28', label: 'SE'},
    {value: '17', label: 'TO'},
  ];

  const valueSelected = arrUF.filter((item) => {
    if (item.value === codigoUF) {
      return item.label;
    }
  });

  return valueSelected[0].label;
};

/*
 *  Array de filtros avançados com UFs
 *  @type {array}
 */
export /** @type {array} */
const PreDefinedRanges = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left',
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
  },
  {
    label: 'Esta semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
  },
  {
    label: 'Este mês',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left',
  },
  {
    label: 'Mês passado',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  },
  {
    label: 'Este ano',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left',
  },
  {
    label: 'Ano passado',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left',
  },
];

//ConsultaNfse máximo 31 dias
export /** @type {array} */
const preDefinedRangesNfse = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left',
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
  },
  {
    label: 'Esta semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
  },
  {
   label: 'Este mês',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left',
  },
  
  ];

  // Status da NFSe nacional segundo Manual de Integração
export const /** @type {array} */
  statusMap = {
     100: {
       situacao: 'Autorizado o uso da NFS-e',
       colorIcon: 'text-success',
       icon: 'mdi-check-circle',
       badgeVariant: 'success', // Variante 'success'
     },
     101: {
       situacao: 'Cancelamento de NFS-e homologado',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     102: {
       situacao: 'Inutilização de número homologada',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     105: {
       situacao: 'Lote em processamento',
       colorIcon: 'text-primary',
       icon: 'mdi-refresh',
       badgeVariant: 'primary', // Variante 'primary'
     },
     106: {
       situacao: 'Lote processado',
       colorIcon: 'text-info',
       icon: 'mdi-check-circle',
       badgeVariant: 'info', // Variante 'info'
     },
     107: {
       situacao: 'Serviço em operação',
       colorIcon: 'text-success',
       icon: 'mdi-check-circle',
       badgeVariant: 'success', // Variante 'success'
     },
     108: {
       situacao: 'Serviço paralisado temporariamente',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     109: {
       situacao: 'Serviço desativado permanentemente',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     110: {
       situacao: 'Uso denegado',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     111: {
       situacao: 'Consulta cadastro realizada com sucesso',
       colorIcon: 'text-success',
       icon: 'mdi-check-circle',
       badgeVariant: 'success', // Variante 'success'
     },
     112: {
       situacao: 'Consulta cadastro não realizada',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     204: {
       situacao: 'Duplicidade de NFS-e',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     215: {
       situacao: 'Falha no Schema XML',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     217: {
       situacao: 'NFS-e não encontrada',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     218: {
       situacao: 'Número da NFS-e já utilizado',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     225: {
       situacao: 'Falha no envio do XML da NFS-e',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     301: {
       situacao: 'Código de município inválido',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     302: {
       situacao: 'Código de atividade econômica inválido',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     303: {
       situacao: 'Código de tributação inválido',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     401: {
       situacao: 'Rejeição: CPF/CNPJ do prestador inválido',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     402: {
       situacao: 'Rejeição: CPF/CNPJ do tomador inválido',
       colorIcon: 'text-danger',
       icon: 'mdi-close-circle',
       badgeVariant: 'danger', // Variante 'danger'
     },
     403: {
       situacao: 'Rejeição: RPS já informado',
       colorIcon: 'text-warning',
       icon: 'mdi-alert-circle',
       badgeVariant: 'warning', // Variante 'warning'
     },
     default: {
       situacao: 'Código desconhecido',
       colorIcon: 'text-secondary',
       icon: 'mdi-alert-circle',
       badgeVariant: 'secondary', // Variante 'secondary'
     },
   };
