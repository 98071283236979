import $ from 'jquery';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {CONFIG} from '../../config/constant';
import {ENDERECO_API} from '../../config/constant';

const JWT_SECRET = CONFIG.jwt.secret;
const jose = require('jose');

const verifyToken = async (uid, empid) => {
  const serviceToken = window.localStorage.getItem('serviceToken');
  const Issuer = 'urn:emid:' + base64_encode(empid);
  const Audience = 'urn:uuid:' + base64_encode(uid);

  const secret = new TextEncoder().encode(JWT_SECRET);
  const {payload} = await jose.jwtVerify(serviceToken, secret, {
    issuer: Issuer,
    audience: Audience,
  });

  return payload.userId;
};

const RestUsersPost = async (config) => {
  const Arr_ = JSON.parse(config.data);
  const caminho = Arr_.dest.trim();
  const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
  const users = JSON.parse(usersRt);
  const emp = users[0];
  const uid = users[3];

  //const verify_ = await verifyToken(uid, emp);
  // verifyToken(uid, emp).then((userId) => {

  const encodedString = emp + '|' + uid;
  //if (verify_ === encodedString) {
  switch (caminho) {
    case 'alteraStatusUsuario':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('alteraStatusUsuario'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'alterarNome':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('alterarNome'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'alterarSenhaUser':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('alterarSenhaUser'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'convite':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost)), base64_encode(users[1])];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('conviteUser'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'deletarGrupo':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('deletarGrupo'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'deletarUsuario':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('deletarUsuario'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'getEmpresasByGrupo':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('empresasPorGrupo'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'getEmpresasByUser':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('empresasPorUsuario'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'getGrupoPermissions':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('permissoesPorGrupo'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));

              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'getUserPermissions':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('permissoesPorUsuario'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'img_perfil':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'POST',
            dataType: 'json',
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('changeImgPerfil'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'novoGrupo':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('novoGrupo'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'changeUserGroup':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('changeUserGroup'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (error) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'reenviarConvite':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              //Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('reenviarConvite'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'savePermissionsByGroup':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('savePermissionsByGroup'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'savePermissionsByUser':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('savePermissionsByUser'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'saveUserGrupo':
      return new Promise(function (resolve, reject) {
        try {
          const {dataPost} = JSON.parse(config.data);
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('saveUserGrupo'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'desvincularConta':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(['']))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('desvincularConta'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
  }
  // } else {
  //   window.location.href = '/auth/login';
  // }
  // });
};

const RestUsersGet = async (config) => {
  const caminho = config.dest.trim();

  let uid = 0;
  let emp = 0;
  let users = [];

  if (sessionStorage.getItem(`ddesp`) !== null) {
    const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
    users = JSON.parse(usersRt);
    emp = users[0];
    uid = users[3];
  }

  // const verify_ = await verifyToken(uid, emp);
  // const encodedString = emp + '|' + uid;
  // if (verify_ === encodedString) {
  switch (caminho) {
    case 'ajuda':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(config.dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',

            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('ajuda'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));

              resolve([200, data]);
            },
            error: function (xhr) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'carregaMenu':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(users[7])];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('carregaMenu'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'empresas':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(users[7])];

          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('tableempresas'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'geratkApiUs':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [users[7]];
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];

          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('geratkApiUs'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = base64_decode(data);
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'getkApi':
      return new Promise(function (resolve, reject) {
        try {
          const usersRt = base64_decode(sessionStorage.getItem(`ddesp`));
          const users = JSON.parse(usersRt);
          const emp = users[0];
          const uid = users[3];

          const {dataPost} = config;
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(dataPost))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('getkApi'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
          });
        } catch (error) {
          if (error.response) {
            reject([400, {message: 'Dados não localizados'}]);
          } else {
            reject([500, {message: 'Server Error'}]);
          }
        }
      });
    case 'logs':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(config.dataPost))];

          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('newGetLog'),
              dd: base64_encode(JSON.stringify(dataPost)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'setores':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid)];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('getSetores'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'setoresEusuarios':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid)];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('carregaSetoreseUsuarios'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'userBysetor':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(JSON.stringify(config.setorId))];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('userBysetor'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'userByTk':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost = [base64_encode(emp), base64_encode(uid), config.dataPost];

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('userByTk'),
              dd: dataPost,
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'userPermissions':
      return new Promise(function (resolve, reject) {
        try {
          const dataPost_ = [base64_encode(emp), base64_encode(uid)];
          const serviceToken = window.localStorage.getItem('serviceToken');

          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('permissoesDoUsuario'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'users':
      return new Promise(function (resolve, reject) {
        try {
          //const { email } = JSON.parse(config.data);
          //
          const dataPost_ = [base64_encode(emp), base64_encode(uid), base64_encode(users[7])];
          const serviceToken = window.localStorage.getItem('serviceToken');
          $.ajax({
            url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
            type: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + serviceToken,
            },
            data: {
              pp: base64_encode('user'),
              ll: base64_encode('tabelausuarios'),
              dd: base64_encode(JSON.stringify(dataPost_)),
            },
            success: async function (data, status, xhr) {
              data = JSON.parse(base64_decode(data));
              resolve([200, data]);
            },
            error: function (xhr, status, error) {
              reject([400, {message: 'Dados não localizados'}]);
            },
          });
          /*axiosN.post('http://192.168.0.33/api/users/listUsers', { 
                          empId: emp 
                      }, { headers: {
                          'Content-Type': 'application/json',
                          'type': 'application/vnd.api+json'
                      }})
                      .then(response => { 
                          resolve([200, response.data])
                      }).catch(err => {
                          
                          reject ([400, { message: 'Dados não localizados' }]);
                        });*/
        } catch (err) {
          reject([500, {message: 'Server Error'}]);
        }
      });
    case 'notificacoes':
      return new Promise(function (resolve, reject) {
        resolve([200, []]);
        // try {
        //   const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        //   const serviceToken = window.localStorage.getItem('serviceToken');
        //   $.ajax({
        //     url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
        //     type: 'GET',
        //     dataType: 'json',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: 'Bearer ' + serviceToken,
        //     },
        //     data: {
        //       pp: base64_encode('user'),
        //       ll: base64_encode('getNotificacoes'),
        //       dd: base64_encode(JSON.stringify(dataPost_)),
        //     },
        //     success: async function (data, status, xhr) {
        //       data = JSON.parse(base64_decode(data));
        //       resolve([200, data]);
        //     },
        //     error: function (xhr, status, error) {
        //       reject([400, {message: 'Dados não localizados'}]);
        //     },
        //   });
        // } catch (err) {
        //   reject([500, {message: 'Server Error'}]);
        // }
      });
    case 'notificacoesLidas':
      return new Promise(function (resolve, reject) {
        resolve([200, []]);
        // try {
        //   const dataPost_ = [base64_encode(emp), base64_encode(uid)];
        //   const serviceToken = window.localStorage.getItem('serviceToken');
        //   $.ajax({
        //     url: `${ENDERECO_API}src/api/controller/espiaoCtrl.class.php`,
        //     type: 'GET',
        //     dataType: 'json',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: 'Bearer ' + serviceToken,
        //     },
        //     data: {
        //       pp: base64_encode('user'),
        //       ll: base64_encode('upNotificacoes'),
        //       dd: base64_encode(JSON.stringify(dataPost_)),
        //     },
        //     success: async function (data, status, xhr) {
        //       data = JSON.parse(base64_decode(data));
        //       resolve([200, data]);
        //     },
        //     error: function (xhr, status, error) {
        //       reject([400, {message: 'Dados não localizados'}]);
        //     },
        //   });
        // } catch (err) {
        //   reject([500, {message: 'Server Error'}]);
        // }
      });
  }
  /*axiosN.post('http://
  }*/
  // } else {
  //   window.location.href = '/auth/login';
  // }
  // });
};

export {RestUsersPost, RestUsersGet};
